html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
html body div {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.login-content {
  width: 100%;
  background: #001529;
  height: inherit;
  margin: auto;
  position: fixed;
}

.login-container {
    margin: auto !important;
    margin-top: 15% !important;
    min-height: 264px;
    min-width: 320px;
    max-width: 412px;
    padding: 36px;
    background-color: #fff;
    box-shadow: 0 2px 3px rgba(0,0,0,.55);
    border: 1px solid #818c94;
    border: 1px solid rgba(0,0,0,.4);
}

.login-container .btn-microsoft-login{
    width: 200px;
    margin-top: 60px;
    margin-left: auto;
}

.fill {
  width:100%;
  height:100%;
  min-height:100%;
  padding:0px;
}

.parent {
  display: flex;
  justify-content: center;
  align-items: center;
}


.sidebar2
{
  height:100%;
  min-height:100%;
  float: left;
  max-width: 50px;
}

.img-fade { /* Safari and Chrome */ /* Firefox */ /* Internet Explorer */ /* Opera */
  animation: fadein 1.5s;
}

@font-face{
    font-family: Segoe Xbox MDL2 Assets;
    src: url(/static/media/FullXboxMDL2Assets.93eb47ce.woff);
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox */

/* Safari and Chrome */

/* Internet Explorer */​

/* Opera */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}
.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 25px;
}
.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  font-size: 24px;
  line-height: 1.33;
  border-radius: 35px;
}

::-webkit-scrollbar
{
  width: 8px;  /* for vertical scrollbars */
}

::-webkit-scrollbar-track
{
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb
{
  background: rgba(0, 0, 0, 0.5);
  outline: 1px solid slategrey;
  border-radius: 8px;
}

body .anticon-search:before {
    font-family: "Segoe Xbox MDL2 Assets" !important;
    content: "\E3F7";
}
body .ant-time-picker-icon:after {
    visibility: hidden;
}
body .clubMenu .club-badge {
    fill: #ccc;
    float: right;
    font-size: 18px;
}
body .repeat-refresh {
    fill: #ccc;
    width: 20px;
}
body .ant-layout-content {
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

#components-layout-demo-side .logo {
  height: 32px;
  background: rgba(255,255,255,.2);
  margin: 16px;
}

.hidden {
    display: none !important;
}

textarea.ant-input.textPostArea {
    max-width: 500px;
}

.clubMenu .ant-menu-submenu-title {
    height: auto !important;
}

.vertical-center-modal {
    text-align: center;
    white-space: nowrap;
}

.vertical-center-modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  width: 0;
}

.ant-modal {
    padding-bottom: 0;
    margin: auto;
}

.vertical-center-modal .ant-modal {
  display: inline-block;
  vertical-align: middle;
  top: 0;
  text-align: left;
}

.ReactVirtualized__List, .ReactVirtualized__Grid {
    outline: 0px;
}

.ant-select-selection {
    overflow: hidden;
}

.ant-select-selection img {
    margin-top: -5px;
}

a:focus {
    outline-offset: 0px !important;
}

.ant-radio-input {
    opacity: 1 !important;
}

input::placeholder {
    color: black !important;
}

textarea::placeholder {
    color: black !important;
}

.ant-select-focused {
    outline: 1.5px solid white !important;
    border: 1.5px solid black !important;
    border-radius: 5px;
}

.btnColor:focus {
    outline: 1.5px solid white !important;
    border: 1.5px solid black !important;
    margin-bottom: 1px !important;
}

.ant-select-selection__placeholder, .btnColor {
    color: black !important;
}

.switch input:not(:checked) ~ small::before {
    background: rgb(158, 176, 190) !important;
}

.switch small:after {
    color: #c4d4e1;
}
.ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active:focus{
    border: 2px dashed #000 !important;
    outline:none;
    margin-top: -2px;
}
.ant-switch {
    border: 1px solid #FFF;
}

.ant-switch-checked::after {
    filter: invert(1);
}

.ant-switch-checked {
    background: #FFF;
    border: 1px solid #000;
    outline: 1px solid #FFF;
}

.ant-switch-checked:focus, .ant-switch:focus {
    border: 1px dashed #000 !important;
    outline: 2px dashed #FFF !important;
}

.ant-pagination-item:focus, .ant-switch-loading-icon,
.ant-pagination-prev:focus,  .ant-pagination-next:focus {
    border: 1px dashed #000 !important;
    outline: 2px dashed #FFF !important;
}

.ant-select-dropdown-menu-item-active {
    border: 2px solid #000 !important;
    outline: 2px solid #FFF;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

.AddChannelButton:hover, .AddChannelButton:focus, .AddChannelButton:focus,.ant-btn:focus,.slickButtonActive:focus  {
    color: #000;
    border: 1.5px solid #000;
    outline: 1.5px solid #fff;
}

.uploadMedia:focus {
    color: #FFF !important;
    background-color: #000 !important;
}

.ant-upload.ant-upload-select-picture-card > .ant-upload:focus {
    border: 2px dotted #000 !important;
    outline: 2px solid #FFF !important;
}

.ant-btn[disabled] {
    color: #000;
}

.recharts-text {
    fill:  #FFF;
    text-shadow: 1px 1px black;
}

@media screen and (max-width: 600px) {
    main {
       min-width: 100% !important;
    }
}


/* styles for the slider */
.slick-list, .slick-slider, .slick-track {
    position:relative;
    display:block
}
.slick-loading .slick-slide, .slick-loading .slick-track {
    visibility:hidden
}

.slick-slider {
    /* our modification */
    min-height: 100%;
    min-height: -webkit-fill-available;
    min-height: stretch;
    display: flex;
    flex-direction: column;
    position: relative;

    box-sizing:border-box;
    -webkit-user-select:none;
    user-select:none;
    -webkit-touch-callout:none;
    -khtml-user-select:none;
    touch-action:pan-y;
    -webkit-tap-highlight-color:transparent
}

.slick-list {
    overflow:hidden;
    margin:0;
    padding:0
}

.slick-list:focus {
    outline:2
}

.slick-list.dragging {
    cursor:pointer;
    cursor:hand
}

.slick-slider .slick-list, .slick-slider .slick-track {
    transform:translate3d(0,0,0)
}

.slick-track{
    top:0;
    left:0;
}

.slick-track:after, .slick-track:before {
    display:table;
    content:''
}

.slick-track:after {
    clear:both
}

.slick-slide {
    display:none;
    float:left;
    height:100%;
    min-height:1px
}

[dir=rtl] .slick-slide {
    float:right
}

.slick-slide img {
    display:block
}

.slick-slide.slick-loading img {
    display:none
}

.slick-slide.dragging img {
    pointer-events:none
}

.slick-initialized .slick-slide {
    display:block
}

.slick-vertical .slick-slide {
    display:block;
    height:auto;
    border:1px solid transparent
}

.slick-arrow.slick-hidden {
    display:none
}
/*# sourceMappingURL=slick.min.css.map */

.slick-prev {
    display: none !important;
}
.slick-next {
    display: none !important;
}
.slick-dots li {
    display: inline-block;
    margin-right: 10px;
}
.slick-dots {
    position: absolute;
    bottom: 0px;

    text-align: center;
    margin: auto;
    width: 100%;
    list-style: none;
    height: 45px;
    padding: 0px;
}
.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    border-radius: 5px;
    color: transparent;
    border: 0;
    outline: none;
    background: #ccc;
    opacity: 0.5;
}

.slick-list .slick-slide {
    opacity: 0;
    min-width: 290px;
    transition: ease-in 0.5s opacity;
}

.slick-list .slick-active {
    opacity: 1 !important;
}

.slick-dots .slick-active button {
    opacity: 1;
    background: #1890ff;
}

.slick-dots li, .slick-dots li button {
    width: 20px;
    height: 5px;
    cursor: pointer;
}

