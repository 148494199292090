html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
html body div {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.login-content {
  width: 100%;
  background: #001529;
  height: inherit;
  margin: auto;
  position: fixed;
}

.login-container {
    margin: auto !important;
    margin-top: 15% !important;
    min-height: 264px;
    min-width: 320px;
    max-width: 412px;
    padding: 36px;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 3px rgba(0,0,0,.55);
    -moz-box-shadow: 0 2px 3px rgba(0,0,0,.55);
    box-shadow: 0 2px 3px rgba(0,0,0,.55);
    border: 1px solid #818c94;
    border: 1px solid rgba(0,0,0,.4);
}

.login-container .btn-microsoft-login{
    width: 200px;
    margin-top: 60px;
    margin-left: auto;
}

.fill {
  width:100%;
  height:100%;
  min-height:100%;
  padding:0px;
}

.parent {
  display: flex;
  justify-content: center;
  align-items: center;
}


.sidebar2
{
  height:100%;
  min-height:100%;
  float: left;
  max-width: 50px;
}

.img-fade {
  -webkit-animation: fadein 1.5s; /* Safari and Chrome */
  -moz-animation: fadein 1.5s; /* Firefox */
  -ms-animation: fadein 1.5s; /* Internet Explorer */
  -o-animation: fadein 1.5s; /* Opera */
  animation: fadein 1.5s;
}

@font-face{
    font-family: Segoe Xbox MDL2 Assets;
    src: url(./fonts/FullXboxMDL2Assets.woff);
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari and Chrome */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}​

/* Opera */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}
.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 25px;
}
.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  font-size: 24px;
  line-height: 1.33;
  border-radius: 35px;
}

::-webkit-scrollbar
{
  width: 8px;  /* for vertical scrollbars */
}

::-webkit-scrollbar-track
{
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb
{
  background: rgba(0, 0, 0, 0.5);
  outline: 1px solid slategrey;
  border-radius: 8px;
}

body .anticon-search:before {
    font-family: "Segoe Xbox MDL2 Assets" !important;
    content: "\E3F7";
}
body .ant-time-picker-icon:after {
    visibility: hidden;
}
body .clubMenu .club-badge {
    fill: #ccc;
    float: right;
    font-size: 18px;
}
body .repeat-refresh {
    fill: #ccc;
    width: 20px;
}
body .ant-layout-content {
    min-height: fit-content;
}
