
/* styles for the slider */
.slick-list, .slick-slider, .slick-track {
    position:relative;
    display:block
}
.slick-loading .slick-slide, .slick-loading .slick-track {
    visibility:hidden
}

.slick-slider {
    /* our modification */
    min-height: 100%;
    min-height: stretch;
    display: flex;
    flex-direction: column;
    position: relative;

    box-sizing:border-box;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    -webkit-touch-callout:none;
    -khtml-user-select:none;
    -ms-touch-action:pan-y;
    touch-action:pan-y;
    -webkit-tap-highlight-color:transparent
}

.slick-list {
    overflow:hidden;
    margin:0;
    padding:0
}

.slick-list:focus {
    outline:2
}

.slick-list.dragging {
    cursor:pointer;
    cursor:hand
}

.slick-slider .slick-list, .slick-slider .slick-track {
    -webkit-transform:translate3d(0,0,0);
    -moz-transform:translate3d(0,0,0);
    -ms-transform:translate3d(0,0,0);
    -o-transform:translate3d(0,0,0);
    transform:translate3d(0,0,0)
}

.slick-track{
    top:0;
    left:0;
}

.slick-track:after, .slick-track:before {
    display:table;
    content:''
}

.slick-track:after {
    clear:both
}

.slick-slide {
    display:none;
    float:left;
    height:100%;
    min-height:1px
}

[dir=rtl] .slick-slide {
    float:right
}

.slick-slide img {
    display:block
}

.slick-slide.slick-loading img {
    display:none
}

.slick-slide.dragging img {
    pointer-events:none
}

.slick-initialized .slick-slide {
    display:block
}

.slick-vertical .slick-slide {
    display:block;
    height:auto;
    border:1px solid transparent
}

.slick-arrow.slick-hidden {
    display:none
}
/*# sourceMappingURL=slick.min.css.map */

.slick-prev {
    display: none !important;
}
.slick-next {
    display: none !important;
}
.slick-dots li {
    display: inline-block;
    margin-right: 10px;
}
.slick-dots {
    position: absolute;
    bottom: 0px;

    text-align: center;
    margin: auto;
    width: 100%;
    list-style: none;
    height: 45px;
    padding: 0px;
}
.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    border-radius: 5px;
    color: transparent;
    border: 0;
    outline: none;
    background: #ccc;
    opacity: 0.5;
}

.slick-list .slick-slide {
    opacity: 0;
    min-width: 290px;
    transition: ease-in 0.5s opacity;
}

.slick-list .slick-active {
    opacity: 1 !important;
}

.slick-dots .slick-active button {
    opacity: 1;
    background: #1890ff;
}

.slick-dots li, .slick-dots li button {
    width: 20px;
    height: 5px;
    cursor: pointer;
}
