.hidden {
    display: none !important;
}

textarea.ant-input.textPostArea {
    max-width: 500px;
}

.clubMenu .ant-menu-submenu-title {
    height: auto !important;
}

.vertical-center-modal {
    text-align: center;
    white-space: nowrap;
}

.vertical-center-modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  width: 0;
}

.ant-modal {
    padding-bottom: 0;
    margin: auto;
}

.vertical-center-modal .ant-modal {
  display: inline-block;
  vertical-align: middle;
  top: 0;
  text-align: left;
}

.ReactVirtualized__List, .ReactVirtualized__Grid {
    outline: 0px;
}

.ant-select-selection {
    overflow: hidden;
}

.ant-select-selection img {
    margin-top: -5px;
}

a:focus {
    outline-offset: 0px !important;
}

.ant-radio-input {
    opacity: 1 !important;
}

input::placeholder {
    color: black !important;
}

textarea::placeholder {
    color: black !important;
}

.ant-select-focused {
    outline: 1.5px solid white !important;
    border: 1.5px solid black !important;
    border-radius: 5px;
}

.btnColor:focus {
    outline: 1.5px solid white !important;
    border: 1.5px solid black !important;
    margin-bottom: 1px !important;
}

.ant-select-selection__placeholder, .btnColor {
    color: black !important;
}

.switch input:not(:checked) ~ small::before {
    background: rgb(158, 176, 190) !important;
}

.switch small:after {
    color: #c4d4e1;
}
.ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active:focus{
    border: 2px dashed #000 !important;
    outline:none;
    margin-top: -2px;
}
.ant-switch {
    border: 1px solid #FFF;
}

.ant-switch-checked::after {
    filter: invert(1);
}

.ant-switch-checked {
    background: #FFF;
    border: 1px solid #000;
    outline: 1px solid #FFF;
}

.ant-switch-checked:focus, .ant-switch:focus {
    border: 1px dashed #000 !important;
    outline: 2px dashed #FFF !important;
}

.ant-pagination-item:focus, .ant-switch-loading-icon,
.ant-pagination-prev:focus,  .ant-pagination-next:focus {
    border: 1px dashed #000 !important;
    outline: 2px dashed #FFF !important;
}

.ant-select-dropdown-menu-item-active {
    border: 2px solid #000 !important;
    outline: 2px solid #FFF;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

.AddChannelButton:hover, .AddChannelButton:focus, .AddChannelButton:focus,.ant-btn:focus,.slickButtonActive:focus  {
    color: #000;
    border: 1.5px solid #000;
    outline: 1.5px solid #fff;
}

.uploadMedia:focus {
    color: #FFF !important;
    background-color: #000 !important;
}

.ant-upload.ant-upload-select-picture-card > .ant-upload:focus {
    border: 2px dotted #000 !important;
    outline: 2px solid #FFF !important;
}

.ant-btn[disabled] {
    color: #000;
}

.recharts-text {
    fill:  #FFF;
    text-shadow: 1px 1px black;
}

@media screen and (max-width: 600px) {
    main {
       min-width: 100% !important;
    }
}
